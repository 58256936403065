import React from "react";
import { TextField } from "@material-ui/core";
// import { Field, FieldProps } from "formik";

export type InputFieldType = {
  // required?: any;
  name: string;
  autoComplete: string;
  field?: any;
  props?: any;
  label?: any;
  type?: string;
  required?: boolean;
  onChange?: any;
  onBlur?: any;
  value?: any;
  defaultValue?: any;
  multiline?: boolean;
  margin?: string;
  rows: number;
};

export const InputField: React.SFC<InputFieldType> = ({
  name,
  label,
  autoComplete,
  required,
  type,
  onChange,
  onBlur,
  value,
  defaultValue,
  multiline,
  rows
}) => {
  return (
    <div>
      <TextField
        required={required}
        name={name}
        label={label}
        fullWidth
        margin="dense"
        variant={"outlined"}
        autoComplete={autoComplete}
        type={type}
        multiline={multiline}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        rows={rows}
      />
    </div>
  );
};
