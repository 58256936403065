import { useState, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

import { Typography, Button, Dialog, Paper } from "@material-ui/core";
import { SubmitButton } from "../../ui/SubmitButton";
import { isMobile } from "react-device-detect";
import { Formik, Form } from "formik";
import { InputField } from "../../ui/InputField";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { meQuery } from "src/graphql/queries/me";
import { meQuery2 } from "src/graphql/queries/me2";
import { userFragment } from "src/graphql/fragments/userFragment";
import { ErrorHandler } from "src/library/ErrorHandler";

import { UserContext } from "src/contexts/UserContext";
import { Redirect } from "src/ui/Redirect";

const loginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...UserInfo
    }
  }

  ${userFragment}
`;

export const Login = () => {
  const history = useHistory();
  const [isValid, setIsValid] = useState(true);
  const { setUserInfo } = useContext(UserContext);

  return (
    <Mutation
      update={(cache, { data }) => {
        if (!data || !data.login) {
          setIsValid(false);

          return;
        }

        cache.writeQuery({
          query: meQuery,
          data: { me: data.login }
        });
      }}
      mutation={loginMutation}
    >
      {(mutate, { client }) => (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Redirect />
          <CardContainer>
            <CustomCard elevation={3}>
              <Typography variant="h6" style={{ marginBottom: 40 }}>
                Login to your account
              </Typography>
              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                onSubmit={async values => {
                  try {
                    // optional cache reset
                    await client.resetStore();
                    await setUserInfo({ email: values.email });
                    await mutate({
                      variables: {
                        email: values.email,
                        password: values.password
                      }
                    });

                    history.push("/account");
                  } catch (e) {
                    setIsValid(e);
                    console.error(e, "eee");
                  }
                }}
              >
                {({ values, handleChange, handleBlur, isSubmitting }) => (
                  <Form>
                    <InputField
                      type="email"
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.email}
                    />
                    <InputField
                      type="password"
                      name="password"
                      label="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.password}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 50,
                        paddingRight: "0px !important"
                      }}
                    >
                      <SubmitButton
                        text="Login"
                        color="primary"
                        width="5em"
                        fontSize=".9rem"
                        variant="contained"
                        backgroundColor={"black"}
                        type="submit"
                        disabled={isSubmitting}
                      />
                    </div>
                    <p style={{ fontSize: "1rem", color: "red" }}>
                      {ErrorHandler(isValid.message)}
                    </p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/register" style={{ alignSelf: "center" }}>
                        Create an account
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </CustomCard>
          </CardContainer>
        </div>
      )}
    </Mutation>
  );
};

const CustomCard = styled(Paper)`
  width: ${isMobile ? "18em" : "30em"};
  padding: ${isMobile ? "1.3em" : "2em"};
  margin: ${isMobile ? "1.3em" : "2em"};
  background-color: #ffffff;
  border-radius: 1%;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;
