import React from "react";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";

export const LogoutView = () => {
  return (
    <ApolloConsumer>
      {client => {
        client.resetStore();
        localStorage.setItem("token", "");
        return <Redirect to="/login" />;
      }}
    </ApolloConsumer>
  );
};
