import React from "react";
import styled from "styled-components";
import { SubmitButton } from "src/ui/SubmitButton";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import ReactGA from "react-ga";

export const ContactModal = () => {
  const history = useHistory();

  const viewTeamPage = () => {
    history.push("/team");
    ReactGA.event({
      category: "view_team_page",
      action: `User viewed team page from home page`
    });
  };
  return (
    <div
      style={{
        padding: "0em 1em 0em 1em",
        marginTop: "0 !important",
        marginBottom: isMobile ? "0em" : "2em",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Text>Need recruiting advice?</Text>
      <SubmitButton
        text="Ask our team!"
        color="black"
        width="5em"
        height="2em"
        fontSize=".9rem"
        variant="contained"
        backgroundColor="#04ed00"
        onClick={viewTeamPage}
      />
    </div>
  );
};

const Text = styled.p`
  font-size: 1rem;
`;
