import { gql } from "apollo-boost";
import { userFragment } from "../fragments/userFragment";

export const meQuery2 = gql`
  query($email: String!) {
    me2(email: $email) {
      ...UserInfo
    }
  }

  ${userFragment}
`;
