export const ErrorHandler = error => {
  if (!error || error === undefined) {
    return null;
  }

  if (error.startsWith("GraphQL")) {
    return error.slice(14);
  } else {
    return error;
  }
};
