import React, { useContext } from "react";
import { gql } from "apollo-boost";
import { userFragment } from "src/graphql/fragments/userFragment";
import { Mutation } from "react-apollo";
import { UserContext } from "src/contexts/UserContext";

const cancelSubscriptionMutation = gql`
  mutation CancelSubscriptionMutation($email: String!) {
    cancelSubscription(email: $email) {
      ...UserInfo
    }
  }

  ${userFragment}
`;

export const CancelSubscription = ({ user }) => {
  const { userInfo, setUserInfo } = useContext(UserContext);

  return (
    <Mutation mutation={cancelSubscriptionMutation}>
      {mutate => (
        <button
          style={{ fontFamily: "JetBrains Mono" }}
          onClick={() => {
            mutate({
              variables: {
                email: user
              }
            });
            setUserInfo({ ...userInfo, type: "free-trial", ccLast4: "" });
          }}
        >
          Cancel subscription
        </button>
      )}
    </Mutation>
  );
};
