import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
const width = window.innerWidth;

export const Timer = ({ timeSet }) => {
  return (
    <TimerContainer>
      <CountDownContainer>
        <CountDown>{timeSet}s</CountDown>
      </CountDownContainer>
      {timeSet === 0 && (
        <FailTextContainer>
          <FailTitle>Too slow!</FailTitle>
          <FailText>Try to have quick, concise answers</FailText>
        </FailTextContainer>
      )}
    </TimerContainer>
  );
};

const TimerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CountDown = styled.p`
  font-size: 16px;
  color: red;
  text-align: center;
  margin: 10px;
`;

const CountDownContainer = styled.div`
  border: 1px solid black;
  width: ${isMobile ? width * 1 : width * 0.9};
  margin: ${isMobile ? "4%" : "1%"};
  border-radius: 5px;
  padding: 0% 2% 0% 2%;
`;

const FailTitle = styled.h2`
  color: red;
  margin-top: -2%;
`;

const FailText = styled.h4`
  margin-top: -4%;
  font-weight: 400;
  color: red;
`;

const FailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
