import React from "react";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Paper, Grid, Typography, Tooltip } from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";

export const Question = ({ id, question, answer, showAnswer }) => {
  const saveQuestion = () => {
    window.alert("Coming soon... We're working hard to build this :)");
    ReactGA.event({
      category: "save_question",
      action: `User saved question #${id}}`
    });
  };
  return (
    <Grid container spacing={0} justify="center" style={{ marginTop: "0em" }}>
      <Paper
        elevation={3}
        style={{
          width: isMobile ? "100%" : "26em",
          margin: "0em 2em 0em 2em",
          border: "solid #f5f5f5 1px",
          borderRadius: "8px",
          overflowY: "scroll",
          padding: "0em 1em 1em 1em"
        }}
      >
        {showAnswer ? (
          <Title>
            <h4>Answer:</h4>
          </Title>
        ) : (
          <Title>
            <h4>Question:</h4>
            <Tooltip title="Save question">
              <BookmarkIcon
                style={{
                  marginTop: ".8em",
                  cursor: "pointer"
                }}
                onClick={saveQuestion}
              />
            </Tooltip>
          </Title>
        )}
        <TextContainer>
          {showAnswer ? <p>{answer}</p> : <p>{question}</p>}
        </TextContainer>
      </Paper>
    </Grid>
  );
};

const Title = styled(Typography)`
  display: flex;
  justify-content: space-between;
  font-size: ${isMobile ? "18px" : "18px"};
  font-weight: 700;
`;

const TextContainer = styled.div`
  font-size: ${isMobile ? ".9rem" : ".9rem"};
  font-weight: 300;
  height: ${isMobile ? "14em" : "14em"};
  background-color: white;
  text-align: flex-start;
  white-space: pre-wrap;
`;

const CustomPaper = styled(Paper)`
  width: ${isMobile ? "100%" : "28em"};
  text-align: center;
  border: solid #f5f5f5 1px;
  border-radius: 8px;
  width: 21em;
  overflow-y: scroll;
  margin: 0em 2em 0em 2em;
`;
