import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Typography, FormControlLabel, Switch } from "@material-ui/core";
import { BrowserRouter as Router, Link } from "react-router-dom";
import ReactGA from "react-ga";

import { SubmitButton } from "../ui/SubmitButton";

const ExternalLink = ({ name, url }) => (
  <Link
    to={{
      pathname: url
    }}
    onClick={() =>
      ReactGA.event({
        category: "profile_click",
        action: `User viewed the profile of ${name}`
      })
    }
    target="_blank"
    style={{
      cursor: "pointer",
      color: "red",
      fontWeight: 600,
      textDecoration: "none"
    }}
  >
    {name}
  </Link>
);

export const Footer = ({ theme, setTheme }) => {
  const handleThemeChange = () => {
    setTheme(!theme);
  };
  return (
    <div
      style={{
        height: "20em",
        display: "flex",
        justifyContent: `${isMobile ? "flex-start" : "flex-end"}`,
        alignContent: "flex-end",
        alignItems: "flex-end"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end"
        }}
      >
        <FormControlLabel
          style={{ marginLeft: ".5em" }}
          control={
            <Switch
              // checked={state.checkedB}
              checked={theme}
              onChange={handleThemeChange}
              name="miami"
              color="black"
            />
          }
          label={<span style={{ fontSize: ".8rem" }}>Miami 🌴</span>}
        />
        <Router>
          <StepContainer>
            <StepTitle variant="subtitle3">{`\xa0\📈\xa0`}</StepTitle>
            <StepTitle variant="subtitle3">
              a project by
              <ExternalLink
                name={`\xa0\Alex Li\xa0`}
                url="https://twitter.com/alexli00"
              />
              and
              <ExternalLink
                name={`\xa0\Adam Kang`}
                url="https://adam-kang.com/"
              />
            </StepTitle>
          </StepContainer>
        </Router>
      </div>
    </div>
  );
};

const StepContainer = styled.div`
  display: flex;
  padding: 1em;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
`;

const StepTitle = styled(Typography)`
  display: flex;
  font-size: 0.8rem;
  margin-left: 0.5em;
  margin-bottom: ${isMobile ? "0.5em" : "0.3em"};
`;
