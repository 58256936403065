import React, { useState, useEffect, useContext } from "react";
import AnnounceKit from "announcekit-react";
import ReactGA from "react-ga";
import KeyboardEventHandler from "react-keyboard-event-handler";
import styled from "styled-components";
import { Question } from "./Question";
import { Timer } from "./Timer";
import { KeyControls } from "./KeyControls";
import data from "../dataFile_answers";
import { ModalContainer } from "./FeedbackModal";
import { SubmitButton } from "../ui/SubmitButton";
import { isMobile } from "react-device-detect";
import { SignUp } from "./Auth/SignUp";
import { ContactModal } from "./Contact/ContactModal";
import { UserContext } from "src/contexts/UserContext";
import { useHistory } from "react-router";
import { Redirect } from "src/ui/Redirect";
import { QuestionList } from "./QuestionContainer/QuestionList";
import { QuestionContext } from "src/contexts/QuestionContext";

export const QuestionContainer = () => {
  const history = useHistory();
  const [newInd, setNewInd] = useState(1);
  const [index, setIndex] = useState(0);
  const [done, setDone] = useState(false);
  const [time, setTime] = useState(data[index].time);
  const [showAnswer, setShowAnswer] = useState(false);
  const { userInfo } = useContext(UserContext);
  const { questionType } = useContext(QuestionContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let len;
  useEffect(() => {
    let intervalId;

    if (time >= 1) {
      intervalId = setInterval(() => {
        const fun = time;
        setTime(fun - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [index, time, questionType]);

  //TO CREATE

  const randomQuestionIndex = num => {
    let numGenerated = Math.floor(Math.random() * num);
    console.log("number generated is: ", numGenerated);
    return numGenerated;
  };

  const repeatQuestion = () => {
    setTime(data[index].time);
  };

  const nextQuestion = async (key, e) => {
    if (key === "enter") {
      await setNewInd(newInd + 1);
      if (index === len - 1) {
        setDone(true);
        setShowAnswer(false);
      } else {
        setIndex(newInd);
        setTime(data[newInd].time);
        setShowAnswer(false);
      }
      ReactGA.event({
        category: "next_question",
        action: `User viewed question number ${newInd + 1}`
      });
    }

    if (key === "space") {
      e && e.preventDefault();
      repeatQuestion();
      ReactGA.event({
        category: "repeat_question",
        action: `User repeated question ${newInd}`
      });
    }
    if (key === "shift") {
      e && e.preventDefault();
      let rndInd = randomQuestionIndex(len);
      setIndex(rndInd);

      setTime(data[rndInd].time);
      setShowAnswer(false);
      ReactGA.event({
        category: "random_question",
        action: `User viewed question number ${rndInd + 1}`
      });
    }

    if (key === "ctrl") {
      e && e.preventDefault();

      if (userInfo && userInfo.type === "gold") {
        showAnswerFunc();
        ReactGA.event({
          category: "view_answer",
          action: `User viewed answer for question ${newInd}`
        });
      }

      if (userInfo && userInfo.type === "free-trial") {
        if (index <= 10) {
          showAnswerFunc();
        } else {
          window.alert("Sign up for a Gold Membership to unlock all answers!");
        }
      }

      if (!userInfo) {
        window.alert("Sign up for a free account to view answers!");
        history.push("/register");
      }
    }
  };

  const chooseNextQuestion = async value => {
    await setNewInd(value);
    if (index === len - 1) {
      setDone(true);
      setShowAnswer(false);
    } else {
      setIndex(value);
      setTime(data[value].time);
      setShowAnswer(false);
    }
    // ReactGA.event({
    //   category: "next_question",
    //   action: `User viewed question number ${newInd + 1}`
    // });
  };

  const userAuthenticatedForNextQuestion = () => {
    if (!userInfo) {
      window.alert("Sign up for a Gold Membership to jump to all questions!");
      history.push("/register");
      return false;
    }

    if (userInfo.type === "free-trial") {
      window.alert("Sign up for a Gold Membership to jump to all questions!");
      history.push("/account");
      return false;
    }

    if (userInfo.type === "gold") {
      return true;
    }
  };

  const showAnswerFunc = () => {
    setShowAnswer(!showAnswer);
  };

  // console.log(questions, "fuk");
  let questionSet;
  const questionsPresented = questionType => {
    if (questionType === "All") {
      len = data.length;
      return data;
    }
    if (questionType === "Behavioral") {
      questionSet = data.filter(question => question.type === 1);
      len = questionSet.length;
      return questionSet;
    }
    if (questionType === "Technical") {
      questionSet = data.filter(question => question.type === 2);
      len = questionSet.length;
      return questionSet;
    }
  };

  const questions = questionsPresented(questionType).map(
    ({ question }) => question
  );

  return (
    <MainContainer>
      <Redirect />
      <QuestionList
        questions={questions}
        chooseNextQuestion={chooseNextQuestion}
        userAuthenticatedForNextQuestion={userAuthenticatedForNextQuestion}
      />
      {done ? (
        "You're done your interview! Refresh the app to practice more."
      ) : (
        <React.Fragment>
          <Question
            id={index}
            question={questionsPresented(questionType)[index].question}
            answer={questionsPresented(questionType)[index].answer}
            showAnswer={showAnswer}
          />
          <Timer timeSet={time} />
          <KeyboardEventHandler
            handleKeys={["enter", "space", "ctrl", "shift"]}
            onKeyEvent={(key, e) => nextQuestion(key, e)}
          />
        </React.Fragment>
      )}

      <KeyControls
        nextQuestion={() => nextQuestion("enter")}
        repeatQuestion={() => nextQuestion("space")}
        showAnswer={() => nextQuestion("ctrl")}
        randQuestion={() => nextQuestion("shift")}
      />
      <div style={{ marginTop: "2em" }}>
        <SubmitButton
          text="Send Feedback!"
          color="primary"
          variant="contained"
          backgroundColor={"black"}
          onClick={handleOpen}
        />
      </div>
      <ContactModal />
      <ModalContainer handleClose={handleClose} open={open} />
      <AnnounceKit widget="https://announcekit.co/widgets/v2/2SjogU"></AnnounceKit>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${isMobile ? "0em" : "2em"};
`;
