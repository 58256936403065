import React, { Fragment, useContext, useEffect } from "react";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { SubmitButton } from "src/ui/SubmitButton";
import { Logout } from "./Logout";
import { PaymentPortal } from "../PaymentPortal/PaymentPortal";
import { ChangeCreditCard } from "../PaymentPortal/ChangeCreditCard";
import { CancelSubscription } from "../PaymentPortal/CancelSubscription";
import { isMobile } from "react-device-detect";
import { UserContext } from "src/contexts/UserContext";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import InfoIcon from "@material-ui/icons/Info";

export const AccountBody = ({ plan, card, user }) => {
  const paidPlan = plan === "free-trial" ? false : true;
  const history = useHistory();

  const upgradeAccount = () => {
    ReactGA.event({
      category: "upgrade_account_pressed",
      action: `User pressed upgrade account button`
    });
  };

  return (
    <div>
      <Grid container spacing={3} justify="center" style={{ marginTop: "3em" }}>
        {paidPlan && "👋🏼 Thanks for being a member!"}
      </Grid>

      <Grid container spacing={3} justify="center" style={{ marginTop: "3em" }}>
        <Grid container spacing={3} justify="center" style={{ margin: "1em" }}>
          <SubmitButton
            text="Keep Prepping 🚀"
            color="black"
            width="10em"
            fontSize="1.6rem"
            variant="contained"
            backgroundColor={"#04ed00"}
            type="submit"
            onClick={() => history.push("/")}
            // disabled={isSubmitting}
          />
        </Grid>
        <Grid item lg={5} xs={11}>
          <CustomPaper elevation={3}>
            <h2>Account</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "80%",
                justifyContent: "space-between"
              }}
            >
              <div>
                <TextContainer>
                  <FontTypography>email:</FontTypography>
                  <FontTypography style={{ color: "red" }}>
                    {user}
                  </FontTypography>
                </TextContainer>
                {/* <TextContainer>
                  <FontTypography>free credits remaining:</FontTypography>
                  <FontTypography style={{ color: "red" }}>
                    {paidPlan ? "UNLIMITED" : "0"}
                  </FontTypography>
                </TextContainer> */}
                <TextContainer>
                  <FontTypography>plan:</FontTypography>
                  <FontTypography style={{ color: "red" }}>
                    {!paidPlan ? "FREE TRIAL (10 answer unlocks)" : "GOLD"}
                  </FontTypography>
                </TextContainer>
                <TextContainer>
                  <FontTypography>card on file:</FontTypography>
                  <FontTypography style={{ color: "red" }}>
                    {card}
                  </FontTypography>
                </TextContainer>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1em",
                  alignContent: "flex-end"
                }}
              >
                {paidPlan && (
                  <ChangeCreditCard user={user}>
                    <button
                      style={{
                        fontFamily: "JetBrains Mono",
                        width: isMobile ? "75%" : "30%",
                        marginBottom: "1em"
                      }}
                    >
                      Update card on file
                    </button>
                  </ChangeCreditCard>
                )}
                <Logout user={user} />
              </div>
            </div>
          </CustomPaper>
        </Grid>
        <Grid item lg={4} xs={11}>
          <CustomPaper style={{ height: isMobile && "30em" }} elevation={3}>
            <h2>{paidPlan ? "You're a gold member!" : "Upgrade to gold"}</h2>
            <p
              style={{
                fontSize: "16px",
                margin: "0",
                textDecoration: "line-through"
              }}
            >
              $10/mo
            </p>
            <div style={{ padding: "0em 0em 1em 0em" }}>
              <Typography
                variant="h4"
                style={{ color: "#edd30e", fontWeight: "600" }}
              >
                $5/mo
              </Typography>
            </div>
            <TextContainer>
              <FontTypography>🔹 Unlimited answers to questions</FontTypography>
            </TextContainer>
            <TextContainer>
              <FontTypography>
                🔹 20 minute Zoom/Email consultation
              </FontTypography>
              <Tooltip title="Email us @ ibprepapp[at]gmail.com once you've signed up!">
                <InfoIcon style={{ color: "#edd30e", fontSize: "16px" }} />
              </Tooltip>
            </TextContainer>
            <TextContainer>
              <FontTypography>🔹 Billed every three months</FontTypography>
            </TextContainer>
            <div style={{ marginTop: "2em" }}>
              {paidPlan ? (
                <CancelSubscription user={user} />
              ) : (
                <PaymentPortal user={user}>
                  <SubmitButton
                    text="Upgrade to unlock answers"
                    color="primary"
                    width="5em"
                    fontSize=".9rem"
                    variant="contained"
                    backgroundColor={"black"}
                    type="submit"
                    height="3em"
                    onClick={() => upgradeAccount()}
                  />
                </PaymentPortal>
              )}
            </div>
          </CustomPaper>
        </Grid>
      </Grid>
    </div>
  );
};

const CustomPaper = styled(Paper)`
  height: 20em;
  padding: 2em;
`;

const TextContainer = styled.div`
  display: flex;
`;

const FontTypography = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-right: 1em;
  margin-top: 0.1em !important;
`;
