import React from "react";

export const Redirect = () => {
  const properURL = window.location.href.includes("ibinterview.com");

  return (
    !properURL && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          padding: "1em 1em 0em 1em"
        }}
      >
        <div>
          Go to our new URL{" "}
          <a
            href="https://ibinterview.com/"
            style={{ color: "red", fontWeight: 1000, textDecoration: "none" }}
          >
            ibinterview.com
          </a>
          {` `}to sign up!
        </div>
      </div>
    )
  );
};
