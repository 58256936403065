import React from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FixedSizeList } from "react-window";
import { QuestionToggle } from "./QuestionToggle";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifySelf: "center",
    justifyContent: "center",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: "0em 1em 1em 1em",
    borderRadius: "5px"
  },
  item: {
    backgroundColor: "#ebebeb"
  }
}));

export const QuestionList = ({
  questions,
  chooseNextQuestion,
  userAuthenticatedForNextQuestion
}) => {
  const classes = useStyles();

  const clickQuestion = index => {
    userAuthenticatedForNextQuestion() && chooseNextQuestion(index);
    ReactGA.event({
      category: "clicked_question",
      action: `User clicked question number ${index}`
    });
  };

  const renderRow = ({ index, style }) => {
    return (
      <ListItem
        onClick={() => clickQuestion(index)}
        button
        class={classes.item}
        style={style}
        key={index}
      >
        <ListItemText>
          <Typography
            noWrap
            style={{ fontSize: "0.8rem", padding: "0em 4em 0em 1em" }}
          >
            {index + 1}. {questions[index]}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      style={{ marginBottom: "1em", marginTop: "1em" }}
    >
      <Paper
        elevation={3}
        style={{
          width: isMobile ? "100%" : "28em",
          margin: "0em 2em 0em 2em",
          border: "solid #f5f5f5 1px",
          borderRadius: "8px"
        }}
      >
        <KeyControlTitle>
          <h4>Question list</h4>
          <QuestionToggle />
        </KeyControlTitle>
        <div className={classes.root}>
          <FixedSizeList
            height={120}
            width={"100%"}
            style={{ borderRadius: "8px" }}
            itemSize={38}
            itemCount={questions.length}
          >
            {renderRow}
          </FixedSizeList>
        </div>
      </Paper>
    </Grid>
  );
};

const KeyControlTitle = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: flex-end;
  padding-right: 1em;
  margin-left: 1em;
`;
// flex-direction: column;
