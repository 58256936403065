const FIT = 1;
const TECHNICAL = 2;

const data = [
  {
    question: "Tell me about yourself",
    time: 60,
    answer: `A good framework would include the following: 
   1. Start with where you grew up
   2. Why you picked your college and major
   3. What got you interested in finance / IB
   4. What you did about that interest (pursued internships, etc)
   5. What you learned from pursuing that interest (internship experiences etc)
   6. How the culmination of all your experiences have led you to this interview.
   `,
    type: FIT
  },
  {
    question: "Why Investment Banking?",
    time: 60,
    answer: `Example of good answers (these are bullet form):
      \n
      1. Want to experience a steep learning coming curve out of school
      \n
      2. Enjoy the concept of meeting and working with like-minded people in the form of an analyst class
      \n
      3. I see myself in both the junior and senior roles of banking (junior because I like analysis, senior because I like sales). `,
    type: FIT
  },
  {
    question: "Walk me through a DCF",
    time: 60,
    answer:
      "A DCF is a way to determine the intrinsic value of the business. There are two many components to a DCF - the projection period, and the terminal value. The projection period is a forecast of the company's unlevered free cash flow over one business cycle, and the terminal value is the value of the business beyond the forecast period, into perpetuity. The sum of these two values, discounted by your weighted average cost of capital, gives you an enterprise value, the main output of the DCF.",
    type: TECHNICAL
  },
  {
    question:
      "Walk me through the three statements when there's a $10 increase in depreciation",
    time: 60,
    answer: `1. $10 decrease in operating profit
    2. clarify tax rate (let's assume 20% here)
    3. Tax decreases by $2
    4. Net income impact is $8 decrease
    5. Cash flow from ops: -$8 net income + $10 add back (depreciation is non-cash expense) = $2 CFO
    6. No other changes to Cash flow statement meaning cash increases by $2 on balance sheet
    7. PP&E goes down by $10
    8. Retained earnings goes down by $8
    9 Thus, Assets ($10 - $2) = liabilities + shareholder's equity ($8) and the balance sheet balances!`,
    type: TECHNICAL
  },
  {
    question:
      "Walk me through the three statements when you buy $100 of inventory in the beginning of the year and sell it for $300 by the end of the year with a 20% tax rate",
    time: 60,
    answer: `
    1. Revenue goes up by $300
    2. Cost of goods sold is $100
    3. Operating profit increases by $200
    4. Tax expense is $40
    5. Net income increases by $160
    6. Cash flow from ops: $160 net income + $100 change in net working capital (inventory goes down, cash goes up)
    7. No other changes to CFS so $260 increase in balance sheet cash
    8. Inventory goes down by $100
    9. Retained earnings goes up by $160
    10. Assets ($260 - $100 = $160) == liabilities + shareholder's equity ($160)
    `,
    type: TECHNICAL
  },
  {
    question: "How do I get from Revenue to unlevered free cash flow?",
    time: 45,
    answer: `
    1. Subtract COGS, Operating Expenses to get EBIT
    2. Multiply EBIT by (1-tax rate) to get Net operating profit after tax (NOPAT)
    3. Add D&A, subtract change in net working capital, and subtract capital expenditures
    `,
    type: TECHNICAL
  },
  {
    question: "What are the different valuation methods? Name at least 4",
    time: 30,
    answer: `
    1. Discounted cash flow
    2. Trading comps
    3. Precedent transactions
    4. Sum of the parts
    5. Dividend discount model`,
    type: TECHNICAL
  },
  {
    question:
      "Between DCF, trading comps, and transaction comps, which one would give me the highest value of a company? Why?",
    time: 30,
    answer: `
    Transaction comps will be the highest given the control premium. However, a DCF has a lot of assumptions that can bring an implied value higher than using precedent transactions`,
    type: TECHNICAL
  },
  {
    question: "How do I get from equity value to enterprise value?",
    time: 15,
    answer: `
    Enterprise value = equity value + debt - cash + preferred stock`,
    type: TECHNICAL
  },
  {
    question: "What is working capital?",
    time: 15,
    answer: `Working capital is current assets less current liabilities.`,
    type: TECHNICAL
  },
  {
    question:
      "If a client approached you and wanted to know if they were over or undervalued, what would you do?",
    time: 45,
    answer: `Run valuation on them across multiple methods (DCF, trading comps, precedent transactions) and show them the range of implied share prices via a football field`,
    type: TECHNICAL
  },
  {
    question:
      "You buy equipment for $100, funded entirely with debt. Interest rate is 10%, of which 5% is PIK and the other is in cash. With the tax rate at 30%, explain the impacts to the three financial statements",
    time: 80,
    answer: `1. Ask for useful life of asset (assume 10 years here)
    2. D&A: +10 -> Operating profit: -$10
    3. Interest expense: -$10 -> Profit before tax: -$20
    4. Tax decreases by $6
    5. Net income decreases by $14
    6. Cash flow from ops: Net income is -$14, add back D&A and half the interest (because of PIK) = $1 CFO
    7. No other changes, balance sheet cash increases by $1
    8. PP&E does down by $10 thus change in assets = -$9
    9. Retained earnings goes down $14, debt goes up by $5 thus change in liabilities + shareholder's equity = -$9
    10. Balance sheet balances!`,
    type: TECHNICAL
  },
  {
    question:
      "Your EV/Sales multiple is 5x and EBITDA margin is 15%, what is your EV/EBITDA multiple?",
    time: 15,
    answer: `Easy to think through this question: Assume Sales = 1, EV = 5, thus EBITDA = 0.15. 
    5 EV / 0.15 EBITDA = 20.0x EV / EBITDA`,
    type: TECHNICAL
  },
  {
    question: "Why do you want to work at our firm?",
    time: 45,
    answer: `Firm specific, but here are a couple generic ones. Would recommend you actually put some thought / research into this.
    1. Spoke to someone in the firm, very high praise and mentioned how collaborative the culture was.
    2. Attracted to the global presence and large platform because that implies lots of career development / mobility opportunities (applicable for most BBs)
    3. Strong across a lot of different products, not just investment banking / M&A (JPM, MS, GS)`,
    type: FIT
  },
  {
    question:
      "Does a weaker USD help or hurt exports? Why and when would it hurt?",
    time: 30,
    answer: `Helps exports. Foreign currency has higher purchasing power. There are nuances but unless you really know what you're talking about, keep it short and sweet.`,
    type: TECHNICAL
  },
  {
    question: "How do you get from EBITDA to FCF?",
    time: 25,
    answer: `Subtract D&A to get EBIT, multiply by (1-tax) to get Net operating profit after tax, add back D&A, change in net working capital and subtract capital expenditures to get FCF
    Formula: uFCF = EBITDA - D&A -> EBIT * (1-tax) -> NOPAT + D&A - change in NWC - CapEx`,
    type: TECHNICAL
  },
  {
    question: "How would a depreciation increase affect your DCF valuation?",
    time: 15,
    answer: `It would increase valuation given that you get a tax shield from having a higher cost, but you add back the full-amount of D&A when bridging to uFCF.
    Example: If D&A goes from 50 -> 100, my operating profit goes down by $50 and if my tax rate is 20%, that means I pay $10 LESS in taxes. Yet I add back the full $100 D&A in my NOPAT -> uFCF calculation.`,
    type: TECHNICAL
  },
  {
    question:
      "If your accounts receivable was increasing faster than your accounts payable, how would that affect your DCF value?",
    time: 15,
    answer: `This would reduce your DCF value, as your working capital would increase by a greater and greater amount every year. We subtract the change, thus it results in lower free cash flow`,
    type: TECHNICAL
  },
  {
    question:
      "Company A has $300mm in EBITDA and it grows at 15% for five years. What is their EBITDA at the end of year 5?",
    time: 45,
    answer: `Use the rule of 72 here (divide 72 by your rate to determine how many years to double your money), 72/15 = ~4.7 meaning your EBITDA is around ~$600mm at the end of year 5`,
    type: TECHNICAL
  },
  {
    question: "How would you go from basic shares to diluted shares?",
    time: 20,
    answer: `Add RSUs and use the treasury stock method for options. We cover treasury stock method in a later question.`,
    type: TECHNICAL
  },
  {
    question:
      "If a company takes on $100mm in debt, does that increase your enterprise value? If so, by how much?",
    time: 20,
    answer: `Depends what you do with the cash. Mathematically, if you increase your debt by 100, your enterprise value doesn't change if cash sits on the balance sheet.
    Remember enterprise value = equity value + debt - cash (debt - cash of $100 would be $0)`,
    type: TECHNICAL
  },
  {
    question:
      "Can you have negative net income but positive cash flow? If so, how?",
    time: 35,
    answer: `Yes. From net income, you can get to positive free cash flows through items such as non-cash expenses, any changes in working capital or asset sales.`,
    type: TECHNICAL
  },
  {
    question: "In a DCF, do you add stock based compensation? Why/why not?",
    time: 30,
    answer: `Yes, because if you don't, you're not accounting for the future dilution that arises from SBC. Said differently, if we didn't subtract it from our DCF, you could have two identical companies with different values based on the cash/stock mix of how they compensated employees`,
    type: TECHNICAL
  },
  {
    question: "How would a change in interest rates affect a DCF model?",
    time: 20,
    answer: `This would impact our WACC through of cost of equity (key input is risk-free rate) and cost of debt.`,
    type: TECHNICAL
  },
  {
    question:
      "Two stocks have the same EBITDA multiple but different P/E ratios, why would this happen?",
    time: 20,
    answer: `They could have different capital structures which would influence their net incomes, and thus their earnings-per-share which would impact their P/E ratio`,
    type: TECHNICAL
  },
  {
    question: "What are the characteristics of an ideal LBO candidate?",
    time: 35,
    answer: `1. Steady predictable free cash flow
    2. Ability to handle large debt load
    3. Lots of areas for cost cutting`,
    type: TECHNICAL
  },
  {
    question:
      "What are your strengths and weaknesses? 3 strengths, 2 weaknesses.",
    time: 35,
    answer: `This is quite personal, but here are a few that are relevant for IB
    Strengths: Thick skin / ability to handle criticism, being able to work under pressure very well, and persistent.
    Weaknesses: Public speaking (analysts don't talk that much...) and creativity (very little creativity required in this job)`,
    type: FIT
  },
  {
    question: "What would your best friend say about you?",
    time: 35,
    answer: `This one is SUPER personal. Try to mention some personal characteristics (loyal, kind, empathetic, etc.).`,
    type: FIT
  },
  {
    question:
      "If I called your last manager, what are some of your areas of improvement that they would tell me?",
    time: 35,
    answer: `Personal, but an example: Manager would say that I should probably speak up on calls and have the confidence to voice my opinion (analysts don't really talk and give their opinion)`,
    type: FIT
  },
  {
    question: "If I called your last manager, what would they say about you?",
    time: 35,
    answer: `Make sure to mention a blend of personal and professional characteristics here. `,
    type: FIT
  },
  {
    question: "What interests you about finance?",
    time: 35,
    answer: `Don't say money. If you're unsure, a safe answer could be how you enjoy analyzing and valuing all the different aspects of businesses`,
    type: FIT
  },
  {
    question:
      "If you weren't recruiting for finance, what would you do be doing? Why aren't you doing that?",
    time: 35,
    answer: `Try not to say something that's actually financially viable. If you say something like consulting or engineering, it could be an easy way to get dinged.`,
    type: FIT
  },
  {
    question: "Why should I hire you?",
    time: 35,
    answer: `This is a derivative of the strengths / weaknesses question. Examples below
    Strengths: Thick skin / ability to handle criticism, being able to work under pressure very well, and persistent.`,
    type: FIT
  },
  {
    question: "Tell me about your latest internship experience",
    time: 45,
    answer: `Framework for describing experiences:
    1. What the company does / key metrics (ex. Worked at a VC fund with $300mm AUM that invests in seed + series A companies)
    2. A high-level overview of responsibilities (ex. Responsible for helping the associate source and analyze deals)
    3. Hone in on one specific experience / attribute of the internship you found extremely interesting (ex. While sourcing, managed to speak to a ton of founders and was extremely inspired)
    4. Try to describe one project with detail where you meaningfully contributed
    
    KEEP IT CONCISE`,
    type: FIT
  },
  {
    question: "Why are you interested in this sector?",
    time: 35,
    answer: `This is too broad to cover. Try to tie in personal experiences, fun facts and why it's an important industry`,
    type: FIT
  },
  {
    question: "How do I know you'll be able to handle 100 hour weeks?",
    time: 35,
    answer: `Most powerful answers are when you give personal examples of crazy 100 hour weeks you've experienced. Generally, you just want to highlight hardships in your life or the lengths you've gone to for achieving something.`,
    type: FIT
  },
  {
    question:
      "This is a very high pressure job, how do you know you'll be able to handle it?",
    time: 35,
    answer: `Try to tie this back to personal experience with specific examples. It's more important to sound confident when answering these types of questions than anything else`,
    type: FIT
  },
  {
    question: "What's your greatest achievement?",
    time: 45,
    answer: `The best way to differentiate yourself on this question is by giving an interesting answer. Could be something as simple as having to raise your sibling growing up and having a great relationship with them. This is your chance to show who you are as a person.`,
    type: FIT
  },

  {
    question: "What's your greatest failure?",
    time: 45,
    answer: `Most people who have the chance to interview in IB probably haven't failed much. A good way to frame your answer is to tell a story where you REALLY wanted something, but ultimately fell short.`,
    type: FIT
  },
  {
    question: "Tell me about a time you had a conflict in a team",
    time: 45,
    answer: `Be specific. Don't villainize the other people in the conflict completely. Admitting your faults is a huge sign of maturity.`,
    type: FIT
  },
  {
    question: "What role do you play on a team?",
    time: 45,
    answer: `There is no right answer. ALWAYS make sure to show that you're a teamplayer.`,
    type: FIT
  },
  {
    question: "What is accretion / dilution?",
    time: 40,
    answer: `The impact to the acquiror's per share financial metric (earnings per share, FCF / share) after the acquisition (also known as "pro forma")`,
    type: TECHNICAL
  },
  {
    question: "Does a dilutive deal mean it's a bad deal?",
    time: 20,
    answer: `No, dilution is a snapshot of what financials might look like in ONE year following a transaction. The strategic and financial benefits may be huge, but realized way down the road that cannot be captured in near term earnings-per-share.`,
    type: TECHNICAL
  },
  {
    question: "Why would two companies merge?",
    time: 35,
    answer: `Lots of reasons, but here are a few (in no particular order).
    1. Cost savings (don't need two CFOs, two HR departments, etc)
    2. Strategic benefits (ex. combining two products to create a superior user experience)
    3. Company wants to take out it's competition
    4. Company wants to expand it's geographic reach
    5. Company wants to enter a new business line and does an acquisition instead of building out the business internally`,
    type: TECHNICAL
  },
  {
    question:
      "Why would a company use stock to purchase another company? List at least 2 reasons",
    time: 20,
    answer: `There are more than two reasons, but here are two for reference:
    1. Not enough cash on the balance sheet
    2. Acquiror is trading at a very high multiple`,
    type: TECHNICAL
  },
  {
    question:
      "If a company with a P/E of 10.0x uses all-stock to buy a company with a P/E of 15.0x, is it accretive or dilutive?",
    time: 35,
    answer: `It is dilutive. Compare inverse P/E's (1/10 vs 1/15), you're trading 10% "yield" for ~6.7%`,
    type: TECHNICAL
  },
  {
    question:
      "Tell me about a deal that's happened in the past two years that you find interesting. What was the multiple paid?",
    time: 45,
    answer: `Framework for speaking to a deal:
    1. Talk about the target / acquiror, the transaction size (if disclosed), and the bankers who advised the deal (google the press release)
    2. Mention the strategic rationale for the deal
    3. 1-2 points why you find it interesting (really interested in the sector, personal relation to the products, etc)
    4. Top it off with valuation multiples (if disclosed) and commentary on whether the multiple paid was fair`,
    type: TECHNICAL
  },
  {
    question: "Tell me about a deal that we've done that you find interesting.",
    time: 45,
    answer: `Framework for speaking to a deal:
    1. Talk about the target / acquiror, the transaction size (if disclosed), and the bankers who advised the deal (google the press release)
    2. Mention the strategic rationale for the deal
    3. 1-2 points why you find it interesting (really interested in the sector, personal relation to the products, etc)
    4. Top it off with valuation multiples (if disclosed) and commentary on whether the multiple paid was fair`,
    type: TECHNICAL
  },
  {
    question: "Are there two companies that you think should merge? Why?",
    time: 45,
    answer: `Here's an example: I believe Facebook should buy Venmo for the following reasons.
    1. Facebook has struggled with getting adoption for their payments products in the US
    2. Venmo is the market leader, and would get Facebook INSTANT access to ~40 million users / connected bank accounts
    3. Facebook has a massive distribution + several products that are already very transactional (marketplaces) and adding payments would effectively turn it into a "super app" like Wechat in China
    4. This could be a potential solution to solving Facebook's declining engagement and would create a ton of new avenues for monetization`,
    type: TECHNICAL
  },
  {
    question:
      "If Company A (10.0x P/E) buys Company B with 50% debt (3% interest rate, 30% tax rate) and 50% stock at 20.0x P/E, is the deal accretive or dilutive?",
    time: 45,
    answer: `1. Inverse the P/E to get to see earnings yield (1/10 = 10%, 1/20 = 5%)
    2. Calculate post-tax interest cost (3% x (1-30%) = 2.1%)
    3. Find the weighted average cost of capital (2.1% x 0.5 + 10% x 0.5 = ~6%)
    4. You're "paying" ~6% cost of capital for 5% "earnings", thus it is dilutive`,
    type: TECHNICAL
  },
  {
    question: "Walk me through an LBO (leveraged buyout) model",
    time: 45,
    answer: `
    1. An LBO model is an analyses for sponsors (private equity firms) to determine the returns that can be generated through purchasing a company with a mixture of equity and debt
    2. The first step is to determine the purchase price of the company, and the mix of debt / equity used to fund the transaction
    3. This lets you build out the sources and uses of capital for the deal
    4. From here, you can make the necessary pro forma adjustments to the company's balance sheet after the sponsor takes over the business
    5. After that, you forecast out the financial performance + cash flow of the business over the life of the LBO, including all the interest payments / debt paydown serviced by the company
    6. Finally, you make assumption around your exit multiple and date to imply an internal rate of return (IRR) for the deal`,
    type: TECHNICAL
  },
  {
    question: "What are the levers I can pull to increase my return in an LBO?",
    time: 45,
    answer: `
    1. Increase leverage
    2. Cut costs of the company (to increase margin)
    3. Lower your purchase price
    `,
    type: TECHNICAL
  },
  {
    question:
      "If I buy a company for 10x EBITDA with 5x debt, pay back half the debt over 5 years while doubling EBITDA over the same period, what is my IRR if I exit at the end of year 5?",
    time: 60,
    answer: `1. Assume EBITDA = 1, thus you're paying $10 for the company, funded with $5 of debt and $5 of equity
    2. If you exit in year 5 with EBITDA of $2, you're exiting for $20 (10.0x * $2)
    3. Subtract $2.5 (you paid down half the debt) from $20 to get to $17.5 for the sponsor
    4. $17.5 / $5 of initial equity = 3.5x multiple on money (MoM)
    5. This implies about a ~30% IRR (use rule of 72)
    `,
    type: TECHNICAL
  },
  {
    question:
      "If I buy a company for 10x EBITDA with 5x debt, pay back half the debt over 5 years, what does my EBITDA need to grow at for me to achieve a 20% IRR assuming I exit at 10.0x at the end of year 5?",
    time: 60,
    answer: `1. Assume EBITDA = 1 -> paying $10 for the company funded with $5 of debt and $5 of equity
    2. Achieving 20% IRR over 5 years implies ~2.5x multiple on money (use rule of 72) -> $5 equity x 2.5 = $12.5 required equity to sponsor at end of year 5
    3. Paying back half the debt means you have $2.5 of debt at end of year 5 -> need to sell the company for $15 ($12.5 required equity + $2.5 debt)
    4. $15 / 10.0x exit = $1.5 of EBITDA required
    5. Going from $1 -> $1.5 is about 8% compounded growth over 5 years
    `,
    type: TECHNICAL
  },
  {
    question:
      "What is interest coverage ratio and leverage ratio. If both were 5x, what is your interest rate on debt?",
    time: 45,
    answer: `1. Interest coverage ratio = EBITDA / Interest payment and leverage ratio = Debt / EBITDA
    2. Assume EBITDA is 1, thus debt = 5 (5x leverage ratio x $1 EBITDA) and interest $ amount is 0.2 (5x interest coverage ratio = $1 of EBITDA / 5x = 0.2)
    3. Thus, 0.2 / 5 = 4% interest rate
    `,
    type: TECHNICAL
  },
  {
    question: "What is mid-year convention?",
    time: 45,
    answer: `In a DCF, if you discount cash flows at the end of the year, you're "over-discounting" since a company's cash flows don't all come at once at the end of the year. The mid year convention solves that by assuming they occur at the midpoint of a given time period.`,
    type: TECHNICAL
  },
  {
    question:
      "How do you get from unlevered free cash flow to levered free cash flow?",
    time: 20,
    answer: `LFCF = UFCF - interest payment x (1-tax) - mandatory debt repayments`,
    type: TECHNICAL
  },
  {
    question: "What is beta?",
    time: 45,
    answer: `A measure of how an individual asset moves (on average) when the overall stock market increases or decreases.`,
    type: TECHNICAL
  },
  {
    question: "What would have a higher beta, Amazon or Pepsi? Why?",
    time: 30,
    answer: `Amazon, more volatility around financial performance and less certainty in what future cash flows will look like.`,
    type: TECHNICAL
  },
  {
    question: "What do you do for fun?",
    time: 30,
    answer: `Just be honest here. Try not to say something too weird though. `,
    type: FIT
  },
  {
    question:
      "If I drink half a cup of water, then pass it to you and you drink half of the remaining amount of water, then pass it back to me and I drink half of the remaining water...etc into infinity, how much more water have I had, the first person to drink the water, than you?",
    time: 60,
    answer: `Two times more, you're drinking 2x more at every turn (0.5 vs 0.25 (0.5 remaining x half)).`,
    type: TECHNICAL
  },
  {
    question: "What is 25 x 25",
    time: 15,
    answer: `625. Make sure to walk through your methodology outloud, otherwise people will assume you're using a calculator.`,
    type: TECHNICAL
  },
  {
    question: "What is 37 x 37",
    time: 20,
    answer: `1368. Make sure to walk through your methodology outloud, otherwise people will assume you're using a calculator.`,
    type: TECHNICAL
  },
  {
    question: "What is WACC? how do you calculate it?",
    time: 45,
    answer: `Weighted average cost of capital (WACC) is the calculation of a firm's cost of capital. We take the corresponding portions of debt, equity, and preferred shares multiplied by their respective costs.`,
    type: TECHNICAL
  },
  {
    question: "How do you calculate cost of equity?",
    time: 30,
    answer: `Using the Capital Asset Pricing Model (CAPM). Formula is as follows:
    CAPM = Risk free rate + (beta x Equity risk premium)
    Equity risk premium is the spread between the returns of the stock market vs the risk free rate.`,
    type: TECHNICAL
  },
  {
    question: "How do you calculate cost of debt?",
    time: 15,
    answer: `Find the company's 10-year cost of debt. Usually you can do this by speaking to LevFin in your bank. Alternatively, you can find the weighted average interest rate of the company's current debt.`,
    type: TECHNICAL
  },
  {
    question: "If interest rates increased by 1%, how would a DCF be impacted?",
    time: 45,
    answer: `Likely decrease the value of the DCF. Your WACC would increase (risk free rate goes up -> increased cost of equity and cost of debt likely higher as well)`,
    type: TECHNICAL
  },
  {
    question: "If tax rates increased by 10%, how would a DCF be impacted?",
    time: 45,
    answer: `Value goes down, you'd pay more taxes and thus have less free cash flow`,
    type: TECHNICAL
  },
  {
    question: "Explain to me what net operating losses (NOL) are.",
    time: 30,
    answer: `Simply speaking, an NOL is a "carry-forward" tax credit when you suffer a loss in your business in a given year. The idea is that if you lose money in a given year, you can offset future profits from tax.`,
    type: TECHNICAL
  },
  {
    question:
      "If you didn't subtract stock-based compensation from your DCF, how would you hypothetically account for future dilution?",
    time: 30,
    answer: `You would account for all the future dilution by converting the RSUs / options into shares (basically impossible to do)`,
    type: TECHNICAL
  },
  {
    question:
      "Who would pay a higher price for a company, a sponsor or a strategic? Why?",
    time: 30,
    answer: `Very likely a strategic. For a couple reasons:
    1. Sponsors likely have a higher return threshold
    2. Strategics can realize synergies
    3. Strategics often have longer time horizons and thus can take their time to realize "value"`,
    type: TECHNICAL
  },
  {
    question: "Would Coke or Pepsi have a higher multiple? Why?",
    time: 30,
    answer: `Likely coke given it has a better brand.`,
    type: TECHNICAL
  },
  {
    question:
      "What does an investment banker do, what qualities do successful bankers have, and how do you fit those?",
    time: 45,
    answer: `1. Investment bankers help their clients raise money through the debt or equity capital markets, and assist in buying or sell assets.
    2. Successful investment bankers are smart, have good attention to detail, and are excellent communicators
    3. Tie your personal points to the #2`,
    type: FIT
  },
  {
    question: "What is your favorite method of valuing a company?",
    time: 45,
    answer: `No right answer, just make to have a bullet proof justification`,
    type: TECHNICAL
  },
  {
    question: "Why would you look at a revenue multiple over a P/E multiple?",
    time: 30,
    answer: `If the company has no profits`,
    type: TECHNICAL
  },
  {
    question:
      "Walk me through the changes you would make on the three financial statements in an accretion/dilution analysis.",
    time: 45,
    answer: `1. Income statement: Take the target operating income and add synergies to acquiror operating income. Subtract any incremental interest expense from new debt.
    2. Cash flow statement: Unless you're doing FCF acc/dil, you don't really need to do anything. Otherwise just combine the operating cash flow and capital expenditures of the two companies
    3. Balance sheet: Determine your new shares outstanding (if any shares were used as consideration for the transaction)`,
    type: TECHNICAL
  },
  {
    question:
      "If you had $10K to invest in a company, what would you invest in and why?",
    time: 60,
    answer: `This is a stock pitch question. Here's a framework for pitching in an interview:
    1. Company name -> description of business -> market cap -> basic operating metrics (revenue and EBITDA + growth / margin) -> basic valuation multiples (P/E, EV/EBITDA)
    2. High level overview of industry and competitors. Try to highlight the company's position in the industry
    3. Investment thesis / Why you would invest in the company
    4. Valuation (trading comps most effective here, mention the multiples of their competitors)
    5. Risks and catalysts
    
    KEEP IT CONCISE`,
    type: TECHNICAL
  },
  {
    question:
      "Say the US computer industry is experiencing declining revenue, but a particular company is experiencing high levels of sales and revenue. What are some reasons why this may happen?",
    time: 45,
    answer: `
    1. They don't operate in the US
    2. They sell a product that is 10x better than an existing one
    3. The company has other business lines that are growing rapidly`,
    type: TECHNICAL
  },
  {
    question:
      "Why would a company's EV/EBITDA multiple be higher or lower than P/E?",
    time: 30,
    answer: `This depends on the company's capital structure. Your P/E is going to be a lot higher if your company is severly levered and have extraordinary interest payments.`,
    type: TECHNICAL
  },
  {
    question:
      "If I promise to give you $2000 per year till you die, what is the maximum amount of money you are willing to give me now? Keep in mind that you need to be reasonable, or I will get it from someone else. ",
    time: 30,
    answer: `Solve this with the perpetuity formula. You can just say $20,000 assuming discount rate of 10% because you can earn a similar return in the market.`,
    type: TECHNICAL
  },
  {
    question: "What are your views on the US economy? Global?",
    time: 60,
    answer: `This is so time-dependent. Talk about a mixture of interest rates / central banks + growth industries + emerging markets. 
    A good way to prep for this question is to have metrics (US GDP growth, fed funds rate, etc) so it looks like you actually know what you're talking about.`,
    type: TECHNICAL
  },
  {
    question: "Why would you use a DCF over the other methodologies?",
    time: 30,
    answer: `1. If there are no comparables / precedent transactions
    2. If it's an EXTREMELY predictable business and markets are going through a trough (recession)`,
    type: TECHNICAL
  },
  {
    question: "What are some problems with a DCF?",
    time: 30,
    answer: `You have to make A LOT of assumptions. It also assumes the business operates into perpetuity.`,
    type: TECHNICAL
  },
  {
    question:
      "Tell me about a time you tried to do too much and failed, or didn’t follow through on a commitment?",
    time: 60,
    answer: `The key to getting through a question like this is to admit failure / fault, but not too much of it. You want to show that you're self-aware and be authentic, but that you're also a smart / hardworking person.`,
    type: FIT
  },
  {
    question: "What is the beta of a lottery ticket?",
    time: 15,
    answer: `0. No relation to the stock market`,
    type: TECHNICAL
  },
  {
    question:
      "Why do you get enterprise value and not equity value from a DCF?",
    time: 15,
    answer: `You're taking the sum of the UNLEVERED free cash flows of the business which is free cash flow to firm. Levered free cash flow (which includes interest and debt payments) would be FCF to equity`,
    type: TECHNICAL
  },
  {
    question:
      "If a company can increase return by using leverage in an LBO, why not leverage a deal 99 to 1?",
    time: 30,
    answer: `1. Your cost of debt would get too expensive and you wouldn't be able to service your interest payments
    2. Your company would go bankrupt if there were any disturbances to predicted performance
    3. No one is going to lend you 99:1 leverage`,
    type: TECHNICAL
  },
  {
    question:
      "I have three companies, a newspaper with a strong Internet presence, a furniture business, and a software company. They are family owned. You are my banker and I want to sell one for liquidity, what do you tell me?",
    time: 60,
    answer: `There is no one right answer here. In our view, the first step is to ask the client a lot of questions to determine what their objectives are before giving them any advice. After that and assessing market conditions, objectives, and valuation multiples - you can give the client advice on what to do.`,
    type: TECHNICAL
  },
  {
    question:
      "What factors would you consider when issuing bonds for a company, say Apple? What expenses would you consider?",
    time: 45,
    answer: `You would want to consider the free cash flow generation capability of the company and the existing debt obligations.
    Expenses to consider: R&D / CapEx spend (you can look at the client's product roadmap), fixed costs (headcount / rent / leases), and unit economics of the products.`,
    type: TECHNICAL
  },
  {
    question:
      "Suppose you have three companies (A start-up software company, an established consumer retail, and a growing healthcare) and three forms of financing (All debt, All equity, Half and Half). Which goes with which?",
    time: 30,
    answer: `Start-up casino: 100% equity
    Established consumer retail: 100% debt
    Growing healthcare company: Half and half`,
    type: TECHNICAL
  },
  {
    question:
      "What is a deferred tax asset (DTA) and what is a deferred tax liability (DTL)?",
    time: 30,
    answer: `Simplistically, a DTA is an asset to off-set future taxes, and a DTL are taxes you owe`,
    type: TECHNICAL
  },
  {
    question:
      "If you have a Debt/EBITDA multiple of 6, and your debt increases by 20%, how much does your EBITDA need to change in order to have a Debt/EBITDA multiple of 4?",
    time: 30,
    answer: `Assume EBITDA is 1 thus debt = 6 -> 20% debt increase means debt goes to 7.2 -> 7.2 of debt / 4.0x multiple = 1.8 EBITDA required meaning 80% more EBITDA is needed.`,
    type: TECHNICAL
  },
  {
    question: "Walk me through PIK interest on the 3 financial statements",
    time: 30,
    answer: `1. Income statement: impacts your interest expense -> profit before tax -> net income
    2. Cash flow statement: You add back the PIK interest in cash flow from operations since it is a non-cash expense
    3. Balance sheet: You add it to debt outstanding.`,
    type: TECHNICAL
  },
  {
    question:
      "If you issue 100mm in equity to buy a target how does that effect your enterprise value?",
    time: 30,
    answer: `Nothing happens when you first issue the equity (equity goes up by 100 and cash goes up by 100 so net impact is 0 to enterprise value). Once you pay the cash the EV increases by $100mm.`,
    type: TECHNICAL
  },
  {
    question: "15^2? 16^2? 17^2?",
    time: 45,
    answer: `Walk through the calculations outloud otherwise people will think you're using a calculator. 15^2 = 225, 16^2 = 256, 17^2 = 289`,
    type: TECHNICAL
  },
  {
    question: "What's your favorite business? why?",
    time: 45,
    answer: `Make sure you either love the products (so you can speak about them in detail) or you know a lot about the business (and you can speak to why it's a very good business)`,
    type: FIT
  },
  {
    question: "General tips",
    time: 30,
    answer: `1. Smile as you're talking (even when you're on the phone, it makes a difference)
    2. Practice practice practice
    3. Remember that the goal of every interview is to have the other person like you, and think that you're a smart and hardworking individual that will fit into their culture`,
    type: FIT
  }
];

export default data;
