import * as React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Query } from "react-apollo";
import { meQuery } from "../graphql/queries/me";
import { meQuery2 } from "../graphql/queries/me2";
import { isMobile } from "react-device-detect";
import { UserContext } from "../contexts/UserContext";

export const Header = () => {
  const { userInfo } = useContext(UserContext);
  let queryPrefix;
  if (userInfo) {
    queryPrefix = "me2";
  } else {
    queryPrefix = "me";
  }

  return (
    <div
      style={{
        height: "2em",
        width: "100%",
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingTop: "1em",
        paddingBottom: "1em"
      }}
    >
      <CustomLink to="/">
        {isMobile ? <h4>📈 IB Prep</h4> : <h2>📈 Investment Banking Prep</h2>}
      </CustomLink>
      <Query
        query={userInfo ? meQuery2 : meQuery}
        variables={userInfo && { email: userInfo.email }}
      >
        {({ data, loading }) => {
          if (loading || !data) {
            return null;
          }

          if (!data[queryPrefix] && !userInfo) {
            return (
              <div style={{ display: "flex" }}>
                <LinkContainer>
                  <CustomLink to="/team">
                    <h5>Coaching</h5>
                  </CustomLink>
                </LinkContainer>
                <LinkContainer>
                  <CustomLink to="/login">
                    <h5>Login</h5>
                  </CustomLink>
                </LinkContainer>
                <LinkContainer>
                  <CustomLink to="/help">
                    <h5>Help</h5>
                  </CustomLink>
                </LinkContainer>
              </div>
            );
          }

          return (
            <div style={{ display: "flex" }}>
              <LinkContainer>
                <CustomLink to="/team">
                  <h5>Coaching</h5>
                </CustomLink>
              </LinkContainer>
              <LinkContainer>
                <CustomLink to="/account">
                  <h5>Account</h5>
                </CustomLink>
              </LinkContainer>
              <LinkContainer>
                <CustomLink to="/help">
                  <h5>Help</h5>
                </CustomLink>
              </LinkContainer>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

const LinkContainer = styled.div`
  margin-right: 0.8em;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  margin: 1em;
`;
