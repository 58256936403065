import React from "react";
import StripeCheckout from "react-stripe-checkout";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { userFragment } from "src/graphql/fragments/userFragment";

const changeCreditCardMutation = gql`
  mutation ChangeCreditCardMutation(
    $source: String!
    $ccLast4: String!
    $email: String!
  ) {
    changeCreditCard(source: $source, ccLast4: $ccLast4, email: $email) {
      ...UserInfo
    }
  }

  ${userFragment}
`;

export const ChangeCreditCard = ({ user, children }) => {
  const mode = process.env.NODE_ENV;
  const devKey = process.env.REACT_APP_STRIPE_PUBLISHABLE;
  const liveKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE;

  return (
    <Mutation mutation={changeCreditCardMutation}>
      {mutate => (
        <StripeCheckout
          token={async token => {
            await mutate({
              variables: {
                source: token.id,
                ccLast4: token.card.last4,
                email: user
              }
            });
          }}
          stripeKey={mode === "development" ? devKey : liveKey}
          name="Update card on file"
          panelLabel="Update card"
        >
          {children}
        </StripeCheckout>
      )}
    </Mutation>
  );
};
