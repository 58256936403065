import React, { useContext } from "react";
import { gql } from "apollo-boost";
import { userFragment } from "src/graphql/fragments/userFragment";
import { Mutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { UserContext } from "src/contexts/UserContext";

const logoutMutation = gql`
  mutation LogoutMutation($email: String!) {
    logout(email: $email) {
      ...UserInfo
    }
  }

  ${userFragment}
`;

export const Logout = ({ user }) => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const history = useHistory();
  return (
    <Mutation mutation={logoutMutation}>
      {mutate => (
        <button
          style={{
            fontFamily: "JetBrains Mono",
            width: isMobile ? "75%" : "30%"
          }}
          onClick={() => {
            mutate({
              variables: {
                email: user
              }
            });
            history.push("/logout");
            localStorage.removeItem("user");
            setUserInfo(false);
          }}
        >
          logout
        </button>
      )}
    </Mutation>
  );
};
