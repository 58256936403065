import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import { QuestionContainer } from "./QuestionContainer";
import { Footer } from "./Footer";
import { UserContext } from "./../contexts/UserContext";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";

export const MainPage = () => {
  ReactGA.initialize("UA-185058702-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { setUserInfo } = useContext(UserContext);

  const [theme, setTheme] = useState(false);

  useEffect(() => {
    try {
      const userDetailsRaw = localStorage.getItem("user");
      const userDetailsParsed = JSON.parse(userDetailsRaw);
      setUserInfo(userDetailsParsed);
    } catch (e) {
      console.log("No stored user");
    }
  }, []);

  return (
    <Container
      style={{
        "background-image":
          theme && "linear-gradient(to bottom, #5edcff, #8affeb)"
        // backgroundColor: theme && "#222222"
      }}
    >
      <QuestionContainer />
      <Footer theme={theme} setTheme={setTheme} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0em;
  flex-direction: column;
`;
