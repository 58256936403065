import React from "react";
import { styled, Paper } from "@material-ui/core";
import { isMobile } from "react-device-detect";

export const Help = () => {
  return (
    <div style={{ margin: "1em" }}>
      <CustomCard elevation={3} spacing={3}>
        <div style={{ padding: "1em" }}>
          <div>
            💌 Email us @ <b>ibprepapp[at]gmail.com</b>
          </div>
          <div style={{ margin: "1em 0em 1em 0em" }}>------</div>
          <div>Fast response times (we were analysts)</div>
          <div style={{ margin: "1em 0em 1em 0em" }}>------</div>
          <div>
            Let us know what you need help with or what we can do better with
            this app!
          </div>
          <div style={{ margin: "1em 0em 1em 0em" }}>------</div>
          <div>thx</div>
        </div>
      </CustomCard>
    </div>
  );
};

const CustomCard = styled(Paper)`
  width: ${isMobile ? "18em" : "30em"};
  padding: ${isMobile ? "1.3em" : "2em"};
  margin: ${isMobile ? "1.3em" : "2em"};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 1%;
`;
