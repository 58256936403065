import React, { useState } from "react";
import { Formik, Form } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { InputField } from "../ui/InputField";

import { SubmitButton } from "../ui/SubmitButton";
import { FormHelperText, Grid } from "@material-ui/core";

async function callingFn(email, feedback) {
  try {
    const response = await fetch(
      "https://v1.nocodeapi.com/akang/google_sheets/kmuidkgRTdlUCFJw?tabId=Sheet1",
      {
        method: "post",
        body: JSON.stringify([[email, feedback]]),
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    const json = await response.json();

    console.log("Success:", JSON.stringify(json));
  } catch (error) {
    console.error("Error:", error);
  }
}

export const ModalContainer = ({ handleClose, open }) => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <Formik
      initialValues={{
        email: "",
        feedback: ""
      }}
      onSubmit={async (values, { resetForm }) => {
        const { email, feedback } = values;

        try {
          await callingFn(email, feedback);
          await setSubmitted(true);

          resetForm();
          handleClose();
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <Form>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <div style={{ padding: "1em" }}>
              Send us feedback!
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      label="Email (optional)"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      label="What would you like to see?"
                      type="feedback"
                      name="feedback"
                      multiline
                      rows="3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.feedback}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    {submitted && (
                      <div>
                        <FormHelperText margin="dense">
                          Thanks!!!
                        </FormHelperText>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <SubmitButton
                      text="Send Feedback"
                      color="primary"
                      variant="contained"
                      backgroundColor={"black"}
                      icon
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
