import React, { useContext } from "react";
import StripeCheckout from "react-stripe-checkout";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { userFragment } from "src/graphql/fragments/userFragment";
import { UserContext } from "src/contexts/UserContext";

const createSubscriptionMutation = gql`
  mutation CreateSubscriptionMutation(
    $source: String!
    $ccLast4: String!
    $email: String!
  ) {
    createSubscription(source: $source, ccLast4: $ccLast4, email: $email) {
      ...UserInfo
    }
  }

  ${userFragment}
`;

export const PaymentPortal = ({ user, card, children }) => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const mode = process.env.NODE_ENV;
  const devKey = process.env.REACT_APP_STRIPE_PUBLISHABLE;
  const liveKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE;

  return (
    <Mutation mutation={createSubscriptionMutation}>
      {mutate => (
        <StripeCheckout
          token={async token => {
            await setUserInfo({
              ...userInfo,
              type: "gold",
              ccLast4: token.card.last4
            });

            await mutate({
              variables: {
                source: token.id,
                ccLast4: token.card.last4,
                email: user
              }
            });
          }}
          stripeKey={mode === "development" ? devKey : liveKey}
        >
          {children}
        </StripeCheckout>
      )}
    </Mutation>
  );
};
