import React, { useContext, useEffect } from "react";
import { Query } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import { PaymentPortal } from "../PaymentPortal/PaymentPortal";
import { meQuery } from "../../graphql/queries/me";
import { meQuery2 } from "../../graphql/queries/me2";
import { ChangeCreditCard } from "../PaymentPortal/ChangeCreditCard";
import { CancelSubscription } from "../PaymentPortal/CancelSubscription";
import { Logout } from "./Logout";
import { AccountBody } from "./AccountBody";
import { UserContext } from "src/contexts/UserContext";

export const Account = () => {
  const { userInfo, setUserInfo } = useContext(UserContext);

  let queryPrefix;
  let query;
  if (userInfo) {
    query = meQuery2;
    queryPrefix = "me2";
  } else {
    query = meQuery;
    queryPrefix = "me";
  }

  return (
    <Query query={query} variables={userInfo && { email: userInfo.email }}>
      {({ data, loading }) => {
        if (loading) {
          return null;
        }

        if (!data) {
          return <div>data is undefined</div>;
        }

        if (!data[queryPrefix] && !userInfo) {
          return <Redirect to="/login" />;
        }

        if (data[queryPrefix].type === "free-trial") {
          let emailInfo;
          const dataParsed = JSON.stringify(data[queryPrefix]);

          localStorage.setItem("user", dataParsed);
          emailInfo = data[queryPrefix].email;

          return (
            <div>
              <AccountBody plan="free-trial" user={emailInfo} />
            </div>
          );
        }

        if (data[queryPrefix].type === "gold") {
          let emailInfo;
          let ccLast4;
          const dataParsed = JSON.stringify(data[queryPrefix]);
          localStorage.setItem("user", dataParsed);
          emailInfo = data[queryPrefix].email;
          ccLast4 = data[queryPrefix].ccLast4;

          return (
            <div>
              <AccountBody plan="gold" card={ccLast4} user={emailInfo} />
            </div>
          );
        }

        if (userInfo) {
          if (userInfo.type === "free-trial") {
            return (
              <div>
                <AccountBody plan="free-trial" user={userInfo.email} />
              </div>
            );
          }
          if (userInfo.type === "gold") {
            return (
              <div>
                <AccountBody
                  plan="gold"
                  card={userInfo.ccLast4}
                  user={userInfo.email}
                />
              </div>
            );
          }
        }
      }}
    </Query>
  );
};
