import React from "react";
import ReactGA from "react-ga";
import { styled, Paper, Grid, makeStyles } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { SubmitButton } from "src/ui/SubmitButton";
import { Link } from "react-router-dom";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import adamHeadshot from "../../../src/assets/headshot.jpg";
import alexHeadshot from "../../../src/assets/alex_headshot.jpeg";

const useStyles = makeStyles(theme => ({
  image: {
    width: isMobile ? "28%" : "20%",
    height: isMobile ? "28%" : "20%",
    borderRadius: 200
  }
}));

const TeamData = [
  {
    name: "Adam Kang",
    headshot: adamHeadshot,
    linkedin: "https://www.linkedin.com/in/adamkang/",
    superPeer: "https://superpeer.com/adamkang",
    bullets: [
      "Analyst @ JPM Tech coverage group for 2 years",
      "Analyst @ BAML Energy coverage group for 1 year",
      "$20bn in total transaction value across M&A, debt and equity financings (incl. Lyft IPO, IDT/Renesas acquisition)",
      "Internships in PE and IB",
      "Now building startups with Alex"
    ]
  },
  {
    name: "Alex Li",
    headshot: alexHeadshot,
    linkedin: "https://www.linkedin.com/in/alexander-li-19900783/",
    superPeer: "https://superpeer.com/adamkang",
    bullets: [
      "Analyst @ JPM Mergers & Acquisitions for 2 years",
      "$30bn in total M&A transaction value",
      "Internships in VC, S&T, IB and private lending",
      "Now building startups with Adam"
    ]
  }
];

export const Team = () => {
  return (
    <>
      <div
        style={{
          margin: "1em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Grid container spacing={2} lg={10} xs={12}>
          <div style={{ padding: "1em" }}>
            <h2>Our Team 🚀</h2>
            Book a call with us to discuss your career goals, interviewing
            tactics, and any other prep related topics.
          </div>
          {TeamData.map(({ name, bullets, linkedin, superPeer, headshot }) => (
            <TeamMember
              name={name}
              headshot={headshot}
              bullets={bullets}
              linkedin={linkedin}
              superPeer={superPeer}
            />
          ))}
        </Grid>
      </div>
    </>
  );
};

const TeamMember = ({ name, bullets, linkedin, superPeer, headshot }) => {
  const classes = useStyles();
  return (
    <Grid item lg={6} xs={12} spacing={2}>
      <CustomCard elevation={3} spacing={3}>
        <div
          style={{
            padding: "1em",
            marginBottom: "1em",
            height: "24em",
            alignItems: "space-between"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b style={{ fontWeight: 800, fontSize: "1.1rem" }}>{name}</b>
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer", color: "#0072b1" }}
            >
              <LinkedInIcon />
            </a>
          </div>
          <div style={{ margin: "1em 0em 1em 0em" }} />
          <div>
            <img
              src={headshot}
              alt="Italian Trulli"
              className={classes.image}
            />
          </div>
          <div style={{ margin: "1em 0em 1em 0em" }} />
          {bullets.map(bullet => (
            <Bullet>
              <b style={{ fontWeight: 800, color: "#d9bf00" }}>-></b> {bullet}
            </Bullet>
          ))}
        </div>
        <ExternalLink text="Book a Call" superPeer={superPeer} name={name} />
      </CustomCard>
    </Grid>
  );
};

const ExternalLink = ({ name, text, superPeer }) => (
  <div style={{ padding: "1.2em" }}>
    <Link
      to={{
        pathname: superPeer
      }}
      onClick={() =>
        ReactGA.event({
          category: `superpeer link clicked: ${name}`
        })
      }
      target="_blank"
      style={{
        cursor: "pointer",
        color: "red",
        fontWeight: 600,
        textDecoration: "none"
      }}
    >
      <SubmitButton
        text={text}
        color="black"
        width="5em"
        fontSize="1rem"
        variant="contained"
        backgroundColor="#04ed00"
      />
    </Link>
  </div>
);

const Bullet = ({ children }) => (
  <div style={{ margin: "1em 0em 1em 0em", fontSize: ".9rem" }}>{children}</div>
);

const CustomCard = styled(Paper)`
  width: ${isMobile ? "18em" : "30em"};
  padding: ${isMobile ? "1.3em" : "2em"};
  margin: ${isMobile ? "1.3em" : "2em"};
  display: flex;
  height: 10em;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 1%;
`;
