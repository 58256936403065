import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Fragment, useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { Elements, StripeProvider } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

import { createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import "./App.css";

import { MainPage } from "./components/MainPage";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import { TOS } from "./components/TOS/TOS";
import { Me } from "./components/Auth/Me";
import { Register } from "./components/Auth/Register";
import { Login } from "./components/Auth/Login";
import { SignUp } from "./components/Auth/SignUp";
import { PaymentPortal } from "./components/PaymentPortal/PaymentPortal";
import { Account } from "./components/Auth/Account";
import { Header } from "./ui/Header";
import { LogoutView } from "./components/Auth/LogoutView";
import { Help } from "./components/Help/Help";
import { Team } from "./components/Contact/Team";
import { UserContext } from "./contexts/UserContext";
import { QuestionContext } from "./contexts/QuestionContext";

const theme = createMuiTheme({
  typography: {
    fontFamily: "JetBrains Mono"
  }
});

const serverUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://ibprep-staging.herokuapp.com";

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({
  uri: `${serverUrl}/graphql`,
  credentials: "include"
});

function App() {
  const [userInfo, setUserInfo] = useState(false);
  const [questionType, setQuestionType] = useState("All");

  return (
    <ApolloProvider client={client}>
      <QuestionContext.Provider value={{ questionType, setQuestionType }}>
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <ThemeProvider theme={theme}>
            <Router>
              <Fragment>
                <div>
                  <Switch>
                    <Route path="/logout" component={LogoutView} />
                    <Route
                      path="/"
                      render={() => (
                        <div>
                          <Header />
                          <Route path="/login" component={Login} />
                          <Route path="/register" component={SignUp} />
                          <Route path="/privacy" component={PrivacyPolicy} />
                          <Route path="/terms" component={TOS} />
                          <Route exact path="/" component={MainPage} />
                          <Route path="/account" component={Account} />
                          <Route path="/help" component={Help} />
                          <Route path="/team" component={Team} />
                        </div>
                      )}
                    />
                  </Switch>
                </div>
              </Fragment>
            </Router>
          </ThemeProvider>
        </UserContext.Provider>
      </QuestionContext.Provider>
    </ApolloProvider>
  );
}

export default App;

// {/* <StripeProvider apiKey="pk_test_UrBUzJWPNse3I03Bsaxh6WFX00r6rJ1YCq"> */}
// <Elements stripe={stripePromise}>
// <Route exact path="/" component={PaymentPortal} />
// </Elements>
// {/* </StripeProvider> */}
