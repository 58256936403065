import React from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import { isMobile } from "react-device-detect";

export const KeyControls = ({
  nextQuestion,
  repeatQuestion,
  showAnswer,
  randQuestion,
  index,
  len
}) => {
  return (
    <Grid container spacing={0} justify="center" style={{ marginTop: "0em" }}>
      <Paper
        elevation={3}
        style={{
          width: isMobile ? "100%" : "28em",
          margin: "0em 2em 0em 2em",
          border: "solid #f5f5f5 1px",
          borderRadius: "8px"
        }}
      >
        <KeyControlTitle>
          <h4>Key controls</h4>
        </KeyControlTitle>
        <ControlButtons>
          <Button
            func={repeatQuestion}
            action="space"
            description="reset timer"
          />
          <Button
            func={nextQuestion}
            action="enter"
            description="next question"
          />
          <Button func={showAnswer} action="ctrl" description="check answer" />
          <Button
            func={randQuestion}
            action="shift"
            description="random question"
          />
        </ControlButtons>
      </Paper>
    </Grid>
  );
};

const Button = ({ func, description, action }) => (
  <div style={{ margin: "0.1em" }}>
    <ControlButton onClick={func}>
      <ButtonText>{isMobile ? description : action}</ButtonText>
    </ControlButton>
    {!isMobile && <DescriptionText>{description}</DescriptionText>}
  </div>
);

const ControlButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0em 1em 0em 1em;
  margin-bottom: 1em;
  justify-content: space-around;
`;

const KeyControlTitle = styled.div`
  display: flex;
  margin-left: 1em;
`;

const ControlButton = styled.div`
  padding: 5px;
  border: solid grey 1px;
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: 0.6rem;
  border-radius: 5px;
  font-size: 15px;
`;

const ButtonText = styled.h5`
  font-size: 0.8rem;
  font-weight: 800;
  margin: 0.8em !important;
`;

const DescriptionText = styled.div`
  font-color: #d3d3d3;
  font-size: 0.8rem;
  margin-right: 1em;
  margin-left: 1em;
  padding-top: 2px;
`;
